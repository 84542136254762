import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import IntroCards from 'components/1080p_Series_CGI_List/IntroCGI';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='1080p Serie CGI List' dateChanged='2019-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='1080p Serie CGI List' image='/images/Search/AU_SearchThumb_CGICommands_1080p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1080p_Serie_CGI_Befehle/' locationFR='/fr/1080p_Series_CGI_List/' crumbLabel="1080p CGIs" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <IntroCards mdxType="IntroCards" />
    {/* * <Link to="/1080p_Serie_CGI_Befehle/Alarm_Menu/">Alarm Menu</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Multimedia_Menu/">Multimedia Menu</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Network_Menu/">Network Menu</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/">Feature Menu</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/System_Menu/">System Menu</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Complete_CGI_List/">Complete CGI List</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Recording_Menu/">Recording Menu</Link> */}
    <h2 {...{
      "id": "list-of-the-most-needed-cgi-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#list-of-the-most-needed-cgi-commands",
        "aria-label": "list of the most needed cgi commands permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`List of the most needed CGI commands`}</h2>
    <p>{`Both ways to authenticate are valid - e.g. for user = admin and password = instar:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://admin:instar@IP/CGI-COMMAND`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://IP/?CGI-COMMAND&usr=admin&pwd=instar`}</code></li>
    </ul>
    <p>{`Both are used interchangeable in this documentation - but your thirdparty application might prefer on or the other.`}</p>
    <h3 {...{
      "id": "alarm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm",
        "aria-label": "alarm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Manually trigger an alert - will show up as Audio Alert in camera log`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=pushhostalarm`}</code></li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "video--snapshot",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#video--snapshot",
        "aria-label": "video  snapshot permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Video & Snapshot`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`VLC Stream (High Resolution)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`rtsp://admin:instar@IP-Address:RTSP-Port/11`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`VLC Stream (Medium Resolution)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`rtsp://admin:instar@IP-Address:RTSP-Port/12`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`VLC Stream (Low Resolution)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`rtsp://admin:instar@IP-Address:RTSP-Port/13`}</code></li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Snapshot (1080p)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/tmpfs/snap.jpg?usr=admin&pwd=instar`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Snapshot (320p)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/tmpfs/auto.jpg?usr=admin&pwd=instar`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Snapshot (160p)`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/tmpfs/auto2.jpg?usr=admin&pwd=instar`}</code></li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`MJPEG Stream 11`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/mjpegstream.cgi?-chn=11&-usr=admin&-pwd=instar`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`MJPEG Stream 12`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/mjpegstream.cgi?-chn=12&-usr=admin&-pwd=instar`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`MJPEG Stream 13`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/mjpegstream.cgi?-chn=13&-usr=admin&-pwd=instar`}</code></li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "pan--tilt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pan--tilt",
        "aria-label": "pan  tilt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pan & Tilt`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Move Up`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/ptzctrl.cgi?-step=&-act=up`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Move Right`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/ptzctrl.cgi?-step=&-act=right`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Move Down`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/ptzctrl.cgi?-step=&-act=down`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Move Left`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/ptzctrl.cgi?-step=&-act=left`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Stop`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/ptzctrl.cgi?-step=&-act=stop`}</code></li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Set current position to saved position 1`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/param.cgi?cmd=preset&-act=set&-status=1&-number=0`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Set current position to saved position 2`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/param.cgi?cmd=preset&-act=set&-status=1&-number=1`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Go to saved position 1`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/param.cgi?cmd=preset&-act=goto&-number=0`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Start horizontal scan`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/param.cgi?cmd=ptzctrl&-step=0&-act=hscan`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Start vertical scan`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`http://IP-Address:Port/param.cgi?cmd=ptzctrl&-step=0&-act=vscan`}</code></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      